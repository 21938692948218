<template>
  <button
    type="button"
    :class="classes"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script>
import "./mkp-button.scss";

export default {
  name: "MkpButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      classes: {
        "mkp-btn": true
      }
    };
  }
};
</script>

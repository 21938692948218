<template>
  <div class="mkp-modal-form">
    <component :is="componentLoader" />
  </div>
</template>

<script>
export default {
  name: "MkpModalForm",
  data: () => {
    return {
      list_form: {
        resetPassword: () => import("./reset-password/mkp-reset-password"),
        contact: () => import("./contact/mkp-contact-form"),
        singup: () => import("./singup/mkp-singup"),
        connection: () => import("./connection/mkp-connection-form")
      }
    };
  },
  computed: {
    step() {
      return this.$store.getters.getModalStep;
    },
    componentLoader() {
      return this.list_form[this.step];
    }
  }
};
</script>

<style scoped></style>

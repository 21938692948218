import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import CategorieModule from "./modules/categorie";
import LoadingModule from "./modules/loading";
import ProductsModule from "./modules/products";
import CartModule from "./modules/cart";
import ModalModule from "./modules/modal";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    CategoriesModule: CategorieModule,
    LoadingModule: LoadingModule,
    ProductsModule: ProductsModule,
    CartModule: CartModule,
    ModalModule: ModalModule
  },
  plugins: [createPersistedState()]
});

<template>
  <div>
    <tiny-slider
      ref="tinySlider"
      v-bind="tinySliderOptions"
      @newBreakpointEnd="updateInfo($event)"
    >
      <slot />
    </tiny-slider>

    <div v-if="navButton">
      <mkp-button
        v-show="showPrevButton"
        class="prev-btn-filter"
        @click.prevent="prev()"
      >
        <slot name="prev-button">prev</slot>
      </mkp-button>
      <mkp-button
        v-show="showNextButton"
        class="next-btn-filter"
        @click.prevent="next()"
      >
        <slot name="next-button">next</slot>
      </mkp-button>
    </div>

    <div v-if="navPagination" class="mkp-pagination">
      <mkp-button
        v-for="(n, i) in info.slideCount"
        :key="i"
        :class="classPagination(n)"
        @click.stop="goTo(n - 1)"
      ></mkp-button>
    </div>
  </div>
</template>

<script>
import VueTinySlider from "./VueTinySlider";
import MkpButton from "../../simple/button/mkp-button";

export default {
  name: "MkpCarousel",
  components: { MkpButton, "tiny-slider": VueTinySlider },
  props: {
    axe: {
      type: String,
      default: "horizontal"
    },
    navButton: {
      type: Boolean,
      default: true
    },
    navPagination: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: [Object, Boolean],
      default: false
    }
  },
  data: () => {
    return {
      info: {},
      index: 0
    };
  },
  computed: {
    tinySliderOptions() {
      return {
        viewportMax: 800,
        items: 1,
        mouseDrag: true,
        loop: false,
        controls: false,
        nav: false,
        axis: this.axe,
        responsive: this.responsive,
        preventScrollOnTouch: "force",
        lazyload: true
      };
    },
    showPrevButton() {
      return !(this.index === 0);
    },
    showNextButton() {
      return this.index + this.info.items < this.info.slideCount;
    }
  },
  mounted() {
    if (this.$refs.tinySlider) {
      this.info = this.$refs.tinySlider.slider.getInfo();
    }

    window.addEventListener("resize", () => {
      if (this.$refs.tinySlider) {
        this.info = this.$refs.tinySlider.slider.getInfo();
      }
    });

    this.$refs.tinySlider.getInfo();
  },
  methods: {
    updateInfo(event) {
      this.info = event;
      this.index = event.index;
    },
    prev() {
      if (this.index > 0) {
        this.index--;
      }
      this.info = this.$refs.tinySlider.slider.getInfo();
      this.$refs.tinySlider.slider.goTo("prev");
    },
    next() {
      if (this.index < this.info.slideCount) {
        this.index++;
      }
      this.info = this.$refs.tinySlider.slider.getInfo();
      this.$refs.tinySlider.slider.goTo("next");
    },
    goTo(index) {
      this.index = index;
      this.info = this.$refs.tinySlider.slider.getInfo();
      this.$refs.tinySlider.slider.goTo(index);
    },
    classPagination(n) {
      return {
        active: n == this.index + 1
      };
    }
  }
};
</script>

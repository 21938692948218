<template>
  <header class="header">
    <div id="logo-container" @click.prevent="$router.push('/home')">
      <mkp-image :lazy-src="logoWhite" />
    </div>
    <MkpButton data-test="open-button" class="open-button" @click="openNav()">
      <span class="lnr lnr-menu"></span>
    </MkpButton>
    <nav
      v-click-outside="onClickOutside"
      data-test="navigation"
      class="navigation"
      :class="{ opened: navigationOpened }"
    >
      <div class="menu-container">
        <ul class="nav-items">
          <li v-for="link in links" :key="link.label" class="nav-item">
            <router-link :to="link.href" active-class="active-link">
              {{ link.label }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="close-button-container">
        <mkp-button
          data-test="close-button"
          class="close-button"
          @click="closeNav()"
        >
          X
        </mkp-button>
      </div>
    </nav>
  </header>
</template>

<script>
import "./mkp-header.scss";
import MkpButton from "@/components/simple/button/mkp-button.vue";
import MkpImage from "@/components/simple/image/mkp-image";

export default {
  name: "MkpHeader",
  components: { MkpImage, MkpButton },
  data() {
    return {
      logoWhite: require("@/assets/images/logo-arkea.png"),
      navigationOpened: false
    };
  },
  computed: {
    links() {
      return [
        {
          label: this.$t("header.links.home"),
          href: "/home"
        },
        {
          label: this.$t("header.links.catalogue"),
          href: "/catalogue"
        }
      ];
    }
  },
  methods: {
    openNav() {
      this.navigationOpened = true;
    },
    onClickOutside(ev) {
      if (
        ev.target.classList.contains("lnr-menu") ||
        ev.target.classList.contains("open-button")
      ) {
        return;
      }
      this.closeNav();
    },
    closeNav() {
      this.navigationOpened = false;
    }
  }
};
</script>

<template>
  <section class="mkp-other-band">
    <div
      v-for="(card, index) in cards"
      :key="index"
      class="mkp-container-other"
    >
      <div class="container-img-other">
        <mkp-image class="mkp-img-other" :lazy-src="card.url" />
      </div>
      <div class="mkp-info-other">
        <h2 class="mkp-title-other">{{ card.title }}</h2>
        <mkp-text-field class="mkp-text-other">
          {{ card.text }}
        </mkp-text-field>
      </div>
    </div>
  </section>
</template>

<script>
import MkpImage from "@/components/simple/image/mkp-image";
import MkpTextField from "@/components/simple/text-field/mkp-text-field";
import "./home-scss/mkp-other-band.scss";

export default {
  name: "MkpOtherBand",
  components: { MkpTextField, MkpImage },
  computed: {
    cards() {
      return [
        {
          url: require("@/assets/images/facile_a_utiliser_1b.png"),
          title: this.$t("home.otherBand.cards.first.title"),
          text: this.$t("home.otherBand.cards.first.text")
        },
        {
          url: require("@/assets/images/facile_a_utiliser_2b.png"),
          title: this.$t("home.otherBand.cards.second.title"),
          text: this.$t("home.otherBand.cards.second.text")
        }
      ];
    }
  }
};
</script>

<style scoped></style>

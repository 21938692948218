<template>
  <section class="mkp-explication-container">
    <div class="explication-title-container">
      <h2 class="explication-title">{{ title }}</h2>
      <mkp-text-field>{{ text }}</mkp-text-field>
    </div>
    <mkp-carousel
      :responsive="carouselOptions"
      :nav-button="false"
      :nav-pagination="true"
      :axe="axis"
      :prevent-sroll-on-touch="'force'"
      class="carousel-explication"
    >
      <mkp-carrousel-slide v-for="(card, index) in cards" :key="index">
        <div class="mkp-card-parent">
          <mkp-card>
            <template v-slot:header>
              <mkp-image :lazy-src="card.url" />
            </template>
            <template v-slot:body>
              <div class="container-inner">
                <h2 class="title-carousel-explication">{{ index + 1 }}</h2>
                <mkp-text-field>{{ card.text }}</mkp-text-field>
              </div>
              <mkp-button
                v-if="card.button"
                @click="goToRouteName(card.button.link)"
                >{{ card.button.text }}
              </mkp-button>
            </template>
          </mkp-card>
        </div>
      </mkp-carrousel-slide>
    </mkp-carousel>
  </section>
</template>

<script>
import MkpTextField from "../../simple/text-field/mkp-text-field";
import MkpCarousel from "../../complex/caroussel/mkp-carousel";
import MkpCarrouselSlide from "../../complex/caroussel/mkp-carousel-slide";
import MkpImage from "../../simple/image/mkp-image";
import MkpButton from "../../simple/button/mkp-button";
import MkpCard from "../../simple/card/mkp-card";
import "./home-scss/mkp-explication-band.scss";
export default {
  name: "MkpExplicationBand",
  components: {
    MkpButton,
    MkpImage,
    MkpCarrouselSlide,
    MkpCarousel,
    MkpTextField,
    MkpCard
  },
  data: vm => {
    return {
      title: vm.$t("home.explicationBand.title"),
      text: vm.$t("home.explicationBand.text"),
      cards: [
        {
          url: require("@/assets/images/comment_ca_marche_1.png"),
          text: vm.$t("home.explicationBand.cards.first.text"),
          button: {
            text: vm.$t("home.explicationBand.cards.first.button"),
            link: "Catalogue"
          }
        },
        {
          url: require("@/assets/images/comment_ca_marche_2.png"),
          text: vm.$t("home.explicationBand.cards.second.text")
        }
      ],

      axis: "vertical",
      carouselOptions: {
        "0": {
          autoHeight: true,
          items: 1
        }
      }
    };
  },
  methods: {
    goToRouteName(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style scoped></style>

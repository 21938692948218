<template>
  <transition name="modal">
    <div class="modal-overlay">
      <div :class="classes">
        <slot>test</slot>
      </div>
    </div>
  </transition>
</template>

<script>
import "./mkp-modal.scss";

export default {
  name: "MkpModal",
  computed: {
    classes() {
      return {
        "mkp-modal-vue": true
      };
    },
    style() {
      return {};
    }
  }
};
</script>

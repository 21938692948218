import axios from "axios";
//import router from "../../router";

const state = {
  categories: [],
  filter: { id: null, label: "TOUT", tags: null }
};

const getters = {
  getCategories: state => state.categories,
  getFilter: state => state.filter
};

const mutations = {
  SET_CATEGORIES: (state, categories) => {
    state.categories = categories;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  }
};

const actions = {
  async loadCategories(context, option) {
    context.commit("START_LOADING");
    axios
      .get(option.url)
      .then(response => {
        let category = [];
        if (option.array) {
          category = category.concat(option.array);
        }
        category = category.concat(response.data);
        context.commit("SET_CATEGORIES", category);
        context.commit("STOP_LOADING");
      })
      .catch(error => {
        console.error(error);
        //router.push({ name: "error" });
      });
  },
  setFilter(context, filter) {
    context.commit("SET_FILTER", filter);
  }
};

const CategorieModule = {
  state,
  getters,
  mutations,
  actions
};

export default CategorieModule;

<template>
  <div class="btn-modal">
    <div @click="$emit('input', true)">
      <slot name="activator"></slot>
    </div>
    <div v-show="value" :class="classesAccount">
      <mkp-modal>
        <template v-slot:default>
          <slot></slot>
        </template>
      </mkp-modal>
    </div>
  </div>
</template>

<script>
import "./mkp-btn-modal.scss";
import MkpModal from "../../simple/modal/mkp-modal";

export default {
  name: "MkpBtnModal",
  components: { MkpModal },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classesAccount() {
      return {
        "mkp-account": true
      };
    },
    style() {
      return {};
    }
  },
  methods: {
    modal(value) {
      this.$emit("open", value);
    }
  }
};
</script>

<template>
  <div class="mkp-header-card-container">
    <section class="mkp-header-card">
      <h1 class="title-page">{{ $t("home.headerCard.title") }}</h1>
      <mkp-text-field class="title-description">{{
        $t("home.headerCard.text")
      }}</mkp-text-field>
      <div class="title-button-container">
        <mkp-button class="button-title" @click.prevent="goCatalog()">{{
          $t("home.headerCard.button")
        }}</mkp-button>
      </div>
    </section>
    <mkp-image
      :lazy-src="require('@/assets/images/background_header_v3.png')"
      class="bg-footer"
    />
  </div>
</template>

<script>
import MkpTextField from "@/components/simple/text-field/mkp-text-field";
import MkpButton from "@/components/simple/button/mkp-button";
import MkpImage from "@/components/simple/image/mkp-image.vue";

export default {
  name: "MkpHeaderCard",
  components: { MkpButton, MkpTextField, MkpImage },
  methods: {
    goCatalog() {
      this.$store.dispatch("setFilter", {
        id: null,
        label: "TOUT",
        tags: null
      });
      this.$router.push({ name: "Catalogue" });
    }
  }
};
</script>

<style scoped lang="scss">
$small-breakpoint: 1024px;

.mkp-header-card-container {
  background: $color-brand;
}

.mkp-header-card {
  width: 100%;
  padding: rem(32) rem(10) rem(25) rem(10);

  .title-page,
  .title-description {
    color: $background-base;
  }

  .title-page {
    margin: rem(16) rem(32);
    font-family: GOTHAM-MEDIUM;
    font-size: $font-xxl;
    font-weight: 600;
    text-align: center;
  }

  .title-description {
    margin-bottom: rem(24);
    padding: 0 rem(10) 0 rem(10);
    text-align: center;
    line-height: 150%;
  }

  .title-button-container {
    width: 80%;
    margin: 0 auto 0 auto;
  }

  .button-title {
    display: block;
    margin: auto;
    padding: rem(16);
    background-color: $background-base;
    box-shadow: 0 8px 16px rgba(57, 57, 95, 0.1);
    color: $color-typo;
    &:hover,
    &:focus {
      background-color: $color-brand-hover;
      color: $background-base;
    }
  }

  @media only screen and (min-width: $small-breakpoint) {
    padding: rem(32) rem(10) rem(96) rem(10);

    .title-page {
      margin: rem(16) rem(32) rem(48) rem(32);
      font-size: $font-xxl;
    }

    .title-description {
      width: rem(700);
      margin-left: auto;
      margin-right: auto;
      padding: 0 rem(0);
      text-align: center;
    }
  }

  @media only screen and (min-width: 1280px) {
    .title-page {
      margin: rem(16) rem(32) rem(48) rem(32);
      font-size: $font-xxxl;
    }

    .title-description {
      width: rem(780);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.bg-footer {
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    margin: 0 auto;
  }
}
</style>

//import axios from 'axios'

const state = {
  contents: [],
  id: "",
  buyer: {},
  customer: {}
};

const getters = {
  getCartContents: state => state.contents,
  getCardId: state => state.id,
  getBuyer: state => state.buyer,
  getCustomer: state => state.customer
};

const mutations = {
  SET_BUYER: (state, buyer) => {
    state.buyer = buyer;
  },
  SET_CUSTOMER: (state, customer) => {
    state.customer = customer;
  },
  SET_CARD: (state, contents) => {
    state.contents = contents;
  },
  ADD_PRODUCT_CARD: (state, product) => {
    let findElem = false;
    state.contents.find(elem => {
      if (elem.id === product.id) {
        elem.quantity += product.quantity;
        findElem = true;
      }
    });
    if (!findElem) {
      state.contents.push(product);
    }
  },
  DELETE_PRODUCT_CARD: (state, product) => {
    state.contents.find((elem, index) => {
      if (elem && elem.id === product.id) {
        elem.quantity -= product.quantity;
        if (elem.quantity === 0) {
          state.contents.splice(index, 1);
        }
      }
    });
  },
  RESET_CARD: state => {
    state.contents = [];
  }
};

const actions = {
  async addProductToCart(context, option) {
    console.log(context, option);
    /*context.commit("START_LOADING");
    axios
            // eslint-disable-next-line no-undef
            .post(option.url + '/' + state.id + '/items', option.product)
            .then(() => {
                console.log('add item to cart on server')
                context.commit("STOP_LOADING");
            })
            .catch(error => {
                console.log(error)
            })*/
  },
  async createCartOnServer(context, option) {
    state.id = 1;
    console.log(context, option);
    /*context.commit("START_LOADING");
     axios
            // eslint-disable-next-line no-undef
            .post(option.url, option.content)
            .then(res => {
                state.id = res.data.id;
                context.commit("STOP_LOADING");
            })
            .catch(error => {
                console.log(error)
            })*/
  },
  async addProduct(context, option) {
    if (state.id) {
      await context.dispatch("addProductToCart", {
        url: option.url,
        product: option.product
      });
    } else {
      await context.dispatch("createCartOnServer", { url: option.url });
    }
    context.commit("ADD_PRODUCT_CARD", option.product);
  },
  async deleteProduct(context, option) {
    context.commit("DELETE_PRODUCT_CARD", option.product);
    /*context.commit("START_LOADING");
        axios
                // eslint-disable-next-line no-undef
                .put(option.url + '/' + context.state.id , {items: context.state.contents})
                .then(() => {
                    console.log('update cart on server');
                    context.commit('DELETE_PRODUCT_CARD', option.product)
                    context.commit("STOP_LOADING");
                })
                .catch(error => {
                    console.log(error)
                })*/
  }
};

const CartModule = {
  state,
  getters,
  mutations,
  actions
};

export default CartModule;

import axios from "axios";
import router from "../../router";

const state = {
  products: [],
  product: {}
};

const getters = {
  getProducts: state => state.products,
  getProduct: state => state.product
};

const mutations = {
  SET_PRODUCTS: (state, products) => {
    state.products = products;
  },
  SET_PRODUCT: (state, product) => {
    state.product = product;
  }
};

const actions = {
  async loadProduct(context, url) {
    context.commit("START_LOADING");

    axios
      .get(url)
      .then(response => {
        context.commit("SET_PRODUCT", response.data);
        context.commit("STOP_LOADING");
      })
      .catch(error => {
        console.error(error);
        router.push({ name: "error" });
      });
  },

  async loadProducts(context, url) {
    context.commit("START_LOADING");

    axios
      .get(url)
      .then(response => {
        context.commit("SET_PRODUCTS", response.data);
        context.commit("STOP_LOADING");
      })
      .catch(error => {
        console.error(error);
        router.push({ name: "error" });
      });
  },

  //NEED DELETE WHEN FILTER WAS IN BACK
  async loadProductsFilters(context, option) {
    context.commit("START_LOADING");
    axios
      .get(option.url)
      .then(response => {
        let productFilter = response.data;
        if (option.filter) {
          productFilter = filterByCategorie(productFilter, option.filter);
        }
        context.commit("SET_PRODUCTS", productFilter);
        context.commit("STOP_LOADING");
      })
      .catch(error => {
        console.error(error);
        router.push({ name: "error" });
      });
  }
};

function filterByCategorie(products, categorytags) {
  let filtered = [];

  products.forEach(product => {
    let alreadyAdd = false;
    for (let arr in product.tags) {
      for (let filter in categorytags) {
        if (
          product.tags[arr].label === categorytags[filter].label &&
          alreadyAdd === false
        ) {
          filtered.push(product);
          alreadyAdd = true;
        }
      }
    }
  });
  return filtered;
}

const ProductsModule = {
  state,
  getters,
  mutations,
  actions
};

export default ProductsModule;

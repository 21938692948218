<template>
  <img
    :data-src="lazySrc"
    :data-srcset="lazySrcset"
    :style="style"
    :alt="alt"
    :class="classes"
  />
</template>

<script>
import "./mkp-image.scss";
import lozad from "lozad";

export default {
  name: "MkpImage",
  props: {
    height: {
      type: Number,
      default: null
    },
    lazySrc: {
      type: String,
      default: null
    },
    lazySrcset: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      classes: {
        mkpImage: true
      }
    };
  },
  computed: {
    style() {
      const style = {};

      if (this.width) style.width = `${this.width}px`;
      if (this.height) style.height = `${this.height}px`;

      return style;
    }
  },
  mounted() {
    const setLoadingState = () => {
      this.loading = false;
    };
    this.$el.addEventListener("load", setLoadingState);
    this.$once("hook:destroyed", () => {
      this.$el.removeEventListener("load", setLoadingState);
    });

    const observer = lozad(this.$el);
    observer.observe();
  }
};
</script>

<template>
  <div class="home">
    <mkp-header-card />
    <mkp-categorie-band />
    <mkp-explication-band />
    <mkp-other-band />
  </div>
</template>

<script>
import MkpHeaderCard from "../../components/views/home/mkp-header-card";
import MkpCategorieBand from "../../components/views/home/mkp-categorie-band";
import MkpExplicationBand from "../../components/views/home/mkp-explication-band";
import MkpOtherBand from "../../components/views/home/mkp-other-band";

export default {
  name: "Home",
  components: {
    MkpOtherBand,
    MkpExplicationBand,
    MkpCategorieBand,
    MkpHeaderCard
  },
  data: () => {
    return {};
  },
  methods: {}
};
</script>

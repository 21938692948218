import VueI18n from "vue-i18n";
import messages from "@/i18n/index";

export default function(vueInstance) {
  vueInstance.use(VueI18n);

  return new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "fr",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
    messages
  });
}

<template>
  <p class="mkp-text"><slot></slot></p>
</template>

<script>
export default {
  name: "MkpTextField"
};
</script>

<style scoped lang="scss">
.mkp-text {
  white-space: pre-line;
  font-size: rem(16);
  font-family: $font-text;
  color: $color-typo;
  letter-spacing: rem(1.2);
  line-height: 110%;
}
</style>

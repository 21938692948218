import Vue from "vue";
import App from "./App.vue";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";

import router from "./router";
import setupI18n from "./i18n";
import store from "./store";
import vClickOutside from "v-click-outside";

Vue.config.productionTip = false;
Vue.config.silent = false;

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(vClickOutside);

const i18n = setupI18n(Vue);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

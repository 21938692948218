<template>
  <div class="mkp-card" @click="$emit('click', $event)">
    <header class="mkp-card__header">
      <slot name="header"></slot>
    </header>
    <section class="mkp-card__body">
      <slot name="body"></slot>
    </section>
    <footer class="mkp-card__footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import "./mkp-card.scss";

export default {
  name: "MkpCard"
};
</script>

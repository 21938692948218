import axios from "axios";

const state = {
  step: "",
  open: false
};

const getters = {
  getModalStep: state => state.step,
  getModalState: state => state.open
};

const mutations = {
  SET_MODAL_STEP: (state, step) => {
    state.step = step;
  },
  SET_MODAL_STATE: state => {
    state.open = !state.open;
  }
};

const actions = {
  setModalStep(context, step) {
    context.commit("SET_MODAL_STEP", step);
  },
  setModalState(context) {
    context.commit("SET_MODAL_STATE");
  },

  async sendRequestAccount(context, option) {
    return axios.post(option.url, option.body);
  }
};

const ModalModule = {
  state,
  getters,
  mutations,
  actions
};

export default ModalModule;

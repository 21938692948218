<template>
  <div>
    <notifications group="default" />

    <notifications group="forms" position="top right">
      <template v-slot:props>
        <div>
          <a class="title">
            {{ props.item.title }}
          </a>
          <mkp-button @click="props.close">
            X
          </mkp-button>
          <div v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import MkpButton from "../../simple/button/mkp-button";
export default {
  name: "MkpNotification",
  components: { MkpButton },
  props: {
    type: {
      type: String,
      default: "info"
    },
    title: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      hidden: false
    };
  },
  methods: {
    hide() {
      this.hidden = true;
    }
  }
};
</script>

<style scoped></style>

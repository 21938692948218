const state = {
  loading: 0
};

const getters = {
  getLoading: state => state.loading
};

const mutations = {
  START_LOADING: state => {
    state.loading += 1;
  },
  STOP_LOADING: state => {
    state.loading -= 1;
  }
};

const actions = {};

const LoadingModule = {
  state,
  getters,
  mutations,
  actions
};

export default LoadingModule;

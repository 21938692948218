import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/catalogue",
    name: "Catalogue",
    component: () => import("../views/catalog/Catalog")
  },
  {
    path: "/products/:id",
    name: "Product",
    props: true,
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/product/Product"),
    redirect: { name: "MkpProductDefaultTemplate" },
    children: [
      {
        path: "",
        name: "MkpProductDefaultTemplate",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/templates/mkp-product-default-template"
          )
      },
      {
        path: "doc",
        name: "MkpRedocWrapper",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../components/simple/redoc-wrapper/mkp-redoc-wrapper"
          )
      }
    ]
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/cart/Cart")
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error/Error")
  },
  {
    path: "*",
    name: "default",
    redirect: "/home"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

<template>
  <a
    :href="href"
    :target="target"
    :class="classes"
    :style="style"
    @click="$emit('click')"
  >
    <slot name="default"></slot>
  </a>
</template>

<script>
import "./mkp-link.scss";

export default {
  name: "MkpLink",
  props: {
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: "_blank"
    }
  },
  computed: {
    classes() {
      return {
        "mkp-link": true
      };
    },
    style() {
      return {};
    }
  }
};
</script>

<template>
  <footer id="footer">
    <div :class="classesFooter">
      <nav class="mkp-nav-footer">
        <ul class="mkp-nav-list-footer">
          <li v-for="link in links" :key="link.label" class="navitems-footer">
            <mkp-link :href="link.href" :class="classLink">
              {{ link.label }}
            </mkp-link>
          </li>
          <!--          <mkp-link @click="loadForm('contact')">-->
          <!--            {{ contact.label }}-->
          <!--          </mkp-link>-->
          <mkp-link href="https://site.arkea-banque-ei.com/contact/">
            {{ contact.label }}
          </mkp-link>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
import "./mkp-footer.scss";
import MkpLink from "../../simple/link/mkp-link";

export default {
  name: "MkpFooter",
  components: { MkpLink },
  data: vm => {
    return {
      links: [
        {
          label: vm.$t("footer.links.mentionLegale"),
          href: "https://site.arkea-banque-ei.com/mentions-legales/"
        },
        {
          label: vm.$t("footer.links.privateLife"),
          href: "https://site.arkea-banque-ei.com/vie-privee-2/"
        }
      ],
      contact: {
        label: vm.$t("footer.links.contact")
      }
    };
  },
  computed: {
    classesFooter() {
      return {
        "mkp-footer": true
      };
    },
    classLink() {
      return {};
    }
  },
  methods: {
    loadForm(form) {
      this.$store.dispatch("setModalStep", form);
      this.$store.dispatch("setModalState");
    }
  }
};
</script>

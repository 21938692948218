<template>
  <div class="mkp-container-card" @click="$emit('click', $event)">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MkpCarouselSlide"
};
</script>

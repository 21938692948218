<template>
  <section class="mkp-categorie">
    <div class="container-categorie-title">
      <h2 class="categorie-title">{{ $t("home.categorieBand.title") }}</h2>
    </div>

    <mkp-carousel
      class="mkp-carousel"
      :responsive="carouselOptions"
      :prevent-sroll-on-touch="false"
    >
      <mkp-carrousel-slide
        v-for="(categorie, index) in categories"
        :key="index"
      >
        <mkp-card @click="goToCatalog(categorie)">
          <template v-slot:header>
            <mkp-image
              :lazy-src="require('@/assets/images/' + categorie.medias[0].url)"
            />
          </template>
          <template v-slot:body>
            <mkp-text-field>{{ categorie.label }}</mkp-text-field>
          </template>
          <template v-slot:footer>
            <div :style="bottomBorderStyles()"></div>
          </template>
        </mkp-card>
      </mkp-carrousel-slide>
      <template v-slot:prev-button>
        <div class="img-btn-container">
          <mkp-image class="btn-prev" :lazy-src="navPrev" />
        </div>
      </template>
      <template v-slot:next-button>
        <div class="img-btn-container">
          <mkp-image class="btn-next" :lazy-src="navNext" />
        </div>
      </template>
    </mkp-carousel>
  </section>
</template>

<script>
import "./home-scss/mkp-categorie-band.scss";

import MkpCarousel from "@/components/complex/caroussel/mkp-carousel";
import MkpCarrouselSlide from "@/components/complex/caroussel/mkp-carousel-slide";
import MkpCard from "@/components/simple/card/mkp-card";
import MkpImage from "@/components/simple/image/mkp-image";
import MkpTextField from "@/components/simple/text-field/mkp-text-field";
import { getValidBaseUrl } from "@/utils/dataValidator.js";

export default {
  name: "MkpCategorieBand",
  components: {
    MkpTextField,
    MkpImage,
    MkpCard,
    MkpCarrouselSlide,
    MkpCarousel
  },
  data() {
    return {
      bottomBorderStyles() {
        return {
          height: "100%",
          width: "100%",
          backgroundColor: "#3c3c46"
        };
      },
      carouselOptions: {
        1441: {
          items: 7,
          gutter: 20,
          center: false
        },
        1280: {
          items: 5,
          gutter: 20,
          center: false
        },
        1024: {
          items: 4,
          gutter: 20,
          center: false
        },
        500: {
          items: 4,
          gutter: 20,
          center: false
        },
        200: {
          items: 1,
          center: true
        }
      },
      navPrev: require("@/assets/images/left.png"),
      navNext: require("@/assets/images/right.png")
    };
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    }
  },
  beforeMount() {
    this.$store.dispatch("loadCategories", {
      url: getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/categories"
    });
  },
  methods: {
    goToCatalog(categorie = null) {
      const filter = categorie ?? { name: "TOUT", tags: null };
      this.$store.commit("SET_FILTER", filter);
      this.$router.push({ name: "Catalogue" });
    }
  }
};
</script>

<template>
  <div id="app" class="app">
    <mkp-header />
    <main>
      <router-view />
      <mkp-btn-modal v-model="modal">
        <mkp-modal-form />
        <mkp-button class="close-modal" @click="closeModal()">X</mkp-button>
      </mkp-btn-modal>
      <mkp-notification />
    </main>
    <mkp-footer />
  </div>
</template>

<script>
import "./styles/reset.scss";

import MkpBtnModal from "@/components/complex/btn-modal/mkp-btn-modal";
import MkpButton from "@/components/simple/button/mkp-button";
import MkpNotification from "@/components/complex/notification/mkp-notification";
import MkpFooter from "@/components/complex/footer/mkp-footer";
import MkpHeader from "@/components/complex/header/mkp-header.vue";
import MkpModalForm from "@/components/complex/forms/mkp-modal-form";

export default {
  components: {
    MkpModalForm,
    MkpHeader,
    MkpFooter,
    MkpNotification,
    MkpBtnModal,
    MkpButton
  },
  computed: {
    form() {
      return this.$store.getters.getModalStep;
    },
    modal() {
      return this.$store.getters.getModalState;
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("setModalState");
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  position: relative;
}
</style>
